<template>
  <div>
    <div style='margin: 10px'>
      <div style="display: flex;flex-direction: row;justify-content: space-between;gap: 10px">
        <div>
          <el-link type='primary' @click='initList'>涨粉绩效目标<span style='color: #1a1a1a;'>与完成情况</span></el-link>
          &nbsp;&nbsp;
          <el-link v-if="userPermissions.indexOf('performance_quotient_edit') !== -1" class='op-link' type='primary'
                   @click='editTable'>
            【{{ groupName }}】
            <i class='el-icon-edit-outline'></i></el-link>
        </div>
        <div>
          <el-link type='primary' @click='initList' icon='el-icon-refresh'>刷新</el-link>
          <el-link v-if="userPermissions.indexOf('performance_log') !== -1" class='op-link' type='primary'
                   @click='showLog'>
            日志
            <i class='el-icon-document'></i></el-link>
          <el-link v-if="userPermissions.indexOf('performance_group_setting') !== -1" class='op-link' type='primary'
                   @click='showSetting'>
            考核设置
            <i class='el-icon-setting'></i></el-link>
        </div>
      </div>
    </div>
    <div class='table-view'>
      <div v-loading='loadingStatus'
           :element-loading-text='loadingText'
           element-loading-spinner='el-icon-loading'>
        <el-table
            :data='tblData'
            :span-method='objectSpanMethod'
            @row-contextmenu='rowContextmenu'
            :row-class-name='rowClassName'
            @header-contextmenu='headerContextmenu'
            :cell-class-name='cellClassName'
            style='width: 100%'
            border>
          <el-table-column
              prop='nickname'
              label='红人昵称'
              align='center'
              min-width='100'>
            <template slot-scope='{row}'>
              <artist-nickname :info='row'/>
              <el-tooltip class='item' effect='dark' content='右键菜单，设置考核平台' placement='right'
                          v-if="userPermissions.indexOf('performance_single_setting') > -1">
                <el-link type='primary'><i class='el-icon-thumb'></i></el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              prop='platform_name'
              label='平台'
              align='center'
              min-width='100'>
          </el-table-column>
          <el-table-column
              prop='average_value'
              label='上季月涨粉均值'
              min-width='100' header-align='center' align='center'>
            <template slot-scope='scope'>
              <span v-if='scope.$index<tblData.length-1'>{{ valFormat(scope.row[`average_value`]) }}</span>
              <span v-else>{{ (scope.row[`average_value`]) }}</span>
            </template>
          </el-table-column>
          <el-table-column v-for='(month,index) in quarterlyMonths' :key='index' :label='`${month}月`'
                           align='center'>
            <el-table-column
                :prop='`quotient_${month}`'
                label='系数'
                min-width='100' header-align='center' align='center'>
              <editable-cell
                  v-if="$index!==tblData.length-2&&userPermissions.indexOf('performance_quotient_edit') !== -1"
                  :show-input='row.editMode'
                  slot-scope='{$index,row,column}'
                  v-model='row[column.property]'
                  @change='editVal(row,column.property,$index)' :is-input-number='false'>
                          <span slot='content'>
                            <span>{{ row[column.property] }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <editable-cell
                  v-else-if="$index===tblData.length-1&&userPermissions.indexOf('performance_bonus_edit') !== -1"
                  :show-input='row.editMode'
                  slot-scope='{$index,row}'
                  v-model='row[`amount_${ month }`]'
                  @change='editVal(row,`amount${ month }`,$index)'
                  :is-input-number='true'>
                          <span slot='content'>
                            <span :class='calcBonusClass(row[`amount_${ month }`])'>{{
                                moneyFormat(row[`amount_${month}`])
                              }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='editMode'></i>
                          </span>
              </editable-cell>
              <template slot-scope='{row,column}' v-else>
                <span>{{ row[column.property] }}</span>
              </template>
            </el-table-column>
            <el-table-column
                :prop='`flag_value_${month}`'
                label='目标'
                min-width='100' header-align='center' align='center'>
              <editable-cell
                  v-if="isNaN(row[`quotient_${ month }`])&&$index<tblData.length-2&&userPermissions.indexOf('performance_quotient_edit') !== -1"
                  :show-input='row.editMode'
                  slot-scope='{$index,row,column}'
                  v-model='row[column.property]'
                  @change='editVal(row,column.property,$index)' :is-input-number='true'>
                          <span slot='content'>
                            <span>{{ row[column.property] || 0 }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='editMode'></i>
                          </span>
              </editable-cell>
              <editable-cell
                  v-else-if="$index===tblData.length-1&&userPermissions.indexOf('performance_bonus_edit') !== -1"
                  :show-input='row.editMode'
                  slot-scope='{$index,row}'
                  v-model='row[`amount_${ month }`]'
                  @change='editVal(row,`amount${ month }`,$index)'
                  :is-input-number='true'>
                          <span slot='content'>
                            <span :class='calcBonusClass(row[`amount_${ month }`])'>{{
                                moneyFormat(row[`amount_${month}`])
                              }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='editMode'></i>
                          </span>
              </editable-cell>
              <template slot-scope='{row,column}' v-else>
                <span>{{ valFormat(row[column.property]) }}</span>
              </template>
            </el-table-column>
            <el-table-column
                :prop='`real_value_${month}`'
                label='完成'
                min-width='100' header-align='center' align='center'>
              <editable-cell
                  v-if="$index===tblData.length-1&&userPermissions.indexOf('performance_bonus_edit') !== -1"
                  :show-input='row.editMode'
                  slot-scope='{$index,row}'
                  v-model='row[`amount_${ month }`]'
                  @change='editVal(row,`amount_${ month }`,$index)'
                  :is-input-number='true'>
                          <span slot='content'>
                            <span :class='calcBonusClass(row[`amount_${ month }`])'>{{
                                moneyFormat(row[`amount_${month}`])
                              }}</span>
                            <!--                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>-->
                          </span>
              </editable-cell>
              <template slot-scope='{row}' v-else>
                <span class='real_value'>{{ valFormat(row[`real_value_${month}`]) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>

      <!--      核算和确认发布-->
      <CheckTrendPanel ref="refCheckPanel"></CheckTrendPanel>
      <!--      红人（KOL）涨粉绩效数据汇总-->
      <!--      <el-row>-->
      <!--        <el-col :span='24'>-->
      <!--          <TrendKolSummary :org-data='dataList' :quarterly-months='quarterlyMonths' />-->
      <!--        </el-col>-->
      <!--      </el-row>-->

    </div>
    <!--    <div v-else>-->
    <!--      <PerformanceTrendPanel ref="refPerformanceTrendPanel2" :search-condition="{...searchCondition}"/>-->
    <!--    </div>-->
    <el-row style='margin-top: 10px'>
      <el-col :span='24'>
        <Tips :data='tipList'/>
      </el-col>
    </el-row>

    <!--    系数修改-->
    <PerformanceQuotientEdit ref='refPerformanceQuotientEdit' @savedEvent='updateQuotientCol'/>
    <!--    修改-->
    <ApeDrawer :drawerData='drawerData' @drawerClose='drawerClose'>
      <template slot='ape-drawer'>
        <PerformanceTrendPanel ref='refPerformanceTrendPanel' :search-condition='condition' style='height: 70vh'/>
      </template>
    </ApeDrawer>
    <!--    日志-->
    <TrendLog v-if="userPermissions.indexOf('performance_log') !== -1" :search-condition.sync='searchCondition'
              :visible='logSetting.visible'
              @close='closeLog'/>

    <!--    单红人绩效考核设置-->
    <PerformancePlatformsSelect ref='refTrendPerformancePlatformsSelect' :performance-type='`trend`'
                                @savedEvent='savedPlatformEvent'/>

    <!--   整组绩效考核设置-->
    <PlatformSetting v-if="userPermissions.indexOf('performance_group_setting') !== -1" :performance-type='`trend`'
                     :search-condition.sync='searchCondition'
                     :visible='performanceSetting.visible' @close='closeSetting'
                     @savedSetting='closeSetting'/>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import {numberFormat} from '@/utils/utils'
import {mapGetters} from 'vuex'
import PerformancePlatformsSelect from '@/pages/performance/components/PerformancePlatformsSelect'
import PerformanceQuotientEdit from '@/pages/performance/components/PerformanceQuotientEdit'
import ApeDrawer from '@/components/ApeDrawer'
import PerformanceTrendPanel from '../components/PerformanceTrendPanel'
import TrendLog from '@/pages/performance/components/TrendLog'
import PlatformSetting from '../components/PlatformSetting'
import TrendKolSummary from '@/pages/performance/components/TrendKolSummary'
import CheckTrendPanel from "@/pages/pa/dept/CheckTrendPanel";

export default {
  name: 'PaTrend',
  components: {
    CheckTrendPanel,
    TrendKolSummary,
    EditableCell,
    PerformancePlatformsSelect,
    PerformanceQuotientEdit,
    ApeDrawer,
    PerformanceTrendPanel,
    TrendLog,
    PlatformSetting
  },
  props: {
    editMode: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      tipList: [
        '目标值 = 上季月均值 * 系数（若系数为“/”，则可手动输入目标值，前提：有录入权限）',
        '上季涨粉均值：求上季度三个月的新增粉丝数/3，并四舍五入取整',
        '数据录入：数据录入后，实时保存，但是其他人不可见，需要发布后才能同步给其他人',
        '数据确认：1)【确认发布】后数据，其他人即可看到最新数据 2)【确认发布】后若继续修改数据，需要重新点击发布其人才能看到最新数据',
        '设置考核：1)单个设置，右键“红人昵称”，可进行配置；2)整组考核设置，点击右上角“考核设置”',
        '批量修改系数：在编辑页面，右键列“系数”，可进行修改',
        '数据版本：已发布/编辑中（有系数录入权限的人才能看[编辑中]的数据）'
      ],
      showIndex: false,//显示序号
      //start:鼠标右键菜单
      visible: false,
      top: 0,
      left: 0,
      //end:鼠标右键菜单
      currentRow: {},
      currentColumn: {},
      platforms: [],//红人所有平台
      showPlatforms: [],//要显示的平台
      showEditIcon: false,//编辑图标
      loadingStatus: false,
      commitLoading: false,
      condition: {},
      searchCondition: {
        // release_status: 'Y',
        // year: null,
        // quarter: 0,
        // group_id: null,
        // group_name: null,
        // months: []
      },
      edit_mode: 'add',
      editStatus: false,
      bonus_items: [],//团队奖金
      dataList: [],//绩效目标数据
      mergeFields: ['nickname'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      calcRow: {nickname: '本组总涨粉', platform_name: '本组总涨粉'},
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      calcFields: [],//本组总涨粉行的计算字段
      bonusRow: {nickname: '团队奖金', platform_name: '团队奖金', average_value: '团队奖金'},//团队奖金行的可编辑列
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      },
      mode: 'drawer',
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '90%',
        show_footer: false
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      logSetting: {
        visible: false
      },
      performanceSetting: {
        visible: false
      },
      checkMonthList: []//已核算的月份

    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    showBonus() {
      //是否有查看展示团队奖金权限
      return this.userPermissions.indexOf('performance_bonus_show') !== -1
    },
    groupName() {
      return this.searchCondition.group_name
    },
    loadingText() {
      return `【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的涨粉绩效目标数据加载中...`
    },
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter
      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    },
    tblData() {
      if (this.dataList.length > 0) {
        let calcRow = this.calcCol()

        //合计数据-初始化
        let appendRows = [{nickname: '本组总涨粉', platform_name: '本组总涨粉', ...calcRow}]
        appendRows.push({...this.bonusRow})

        return [...this.dataList, ...appendRows]
      }

      return []
    },
    formQuotientTitle() {
      return `批量修改【${this.formQuotient.year}年${this.formQuotient.month}月】【${this.searchCondition.group_name}】涨粉绩效系数`
    }
    // condition() {
    //   return {
    //     release_status: 'N',
    //     year: this.searchCondition.year,
    //     quarter: this.searchCondition.quarter,
    //     group_id: this.searchCondition.group_id,
    //     group_name: this.searchCondition.group_name,
    //     months: this.searchCondition.months,
    //   }
    // }
  },
  // watch: {
  //   searchCondition: {
  //     deep: true,
  //     handler() {
  //       // console.log('searchCondition',val)
  //       this.initList()
  //     }
  //   }
  // },
  mounted() {
    this.getPlatforms()
  },
  methods: {
    show(query) {
      this.searchCondition = query
      this.initList()
    },
    async getPlatforms() {
      // console.log('get platform 1')
      // 获取最新平台信息
      let {list} = await this.$api.getPlatforms()
      this.platforms = list
    },
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    // eslint-disable-next-line
    colFormatter(row, column, cellValue, index) {
      row[column.property] = '平台：' + row[column.property]
    },
    // eslint-disable-next-line
    mouseEnter(row, column, cell, event) {
      console.log(`当单元格 hover 进入时会触发该事件:第${row.$index},列${column.property} }`)
    },
    // eslint-disable-next-line
    editVal(row, colName, rowIndex) {
      this.editStatus = true
      // console.log(`当前操作第${ rowIndex },列${ colName },isNaN:${ isNaN(row[colName]) }`)
      if (!isNaN(row[colName])) {
        row[colName] = row[colName] * 1
        //修改系数值-同步计算目标值
        if (colName.indexOf('quotient_') > -1) {
          let arr = colName.split('_')
          let syncField = `flag_value_${arr[1]}`
          row[syncField] = row[colName] * row['average_value']
        }

        //团队奖金数据同步，重新计算表格数据的时候要使用
        if (colName.indexOf('amount_') > -1) {
          this.bonusRow[colName] = row[colName]
          this.saveBonus(row, colName)
        } else {
          //实时保存
          this.save(row, colName)
        }
      }


    },
    init(condition) {
      this.searchCondition = condition
      this.$nextTick(() => {
        if (this.$refs['refTrendPerformancePlatformsSelect'])
          this.$refs['refTrendPerformancePlatformsSelect'].closeMenu()
      })
      this.initList()
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field])
          condition[field] = this.searchCondition[field]
      })

      return condition
    },
    async initList() {
      //计算的列
      this.getCalcFields()
      //当前已核算完成的月份
      // await this.getCheckMonths()
      //重置数据
      this.loadingStatus = true
      this.dataList = []
      let {
        list, edit_mode, bonus_items, checked_months
        // } = await this.$api.getPerformanceTrendQuarterData(this.handleSearchCondition())
      } = await this.$api.getPaDeptTrendDeptData(this.handleSearchCondition())

      this.checkMonthList = checked_months || []
      this.edit_mode = edit_mode
      this.bonus_items = bonus_items
      // console.log(this.bonus_items)
      if (this.bonus_items.length > 0) {
        let row = {}
        this.bonus_items.forEach((item) => {
          row[`amount_${item.month}`] = item.amount
        })
        this.bonusRow = {nickname: '团队奖金', platform_name: '团队奖金', average_value: '团队奖金', ...row}
      }
      this.$nextTick(() => {
        this.dataList = list
        this.loadingStatus = false
        this.$refs['refCheckPanel'].show(this.searchCondition, checked_months)

        this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      })
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.tblData.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.tblData[index][field] === this.tblData[index - 1][field]) {
              this.spanObj[field][this.positionObj[field]] += 1
              this.spanObj[field].push(0)
            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      //末尾查询一行合计：团队奖金
      if (rowIndex === this.tblData.length - 1) {
        // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
        if ((columnIndex + 1) % 3 === 0) {
          return [1, 3]
        } else {
          return [0, 0]
        }
      } else if (rowIndex === this.tblData.length - 2) {
        // 本组总涨粉：
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 2]
        } else {
          return [1, 1]
        }
      } else {
        const fields = this.mergeFields
        if (column.property && fields.indexOf(column.property) > -1) {
          const _row = this.spanObj[column.property][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }

    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')
      this.bonusRow = {nickname: '团队奖金', platform_name: '团队奖金', average_value: '团队奖金'}
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化
          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    //计算列的合计
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1
              }
            })
          }
        })
      }
      return calcRow
    },

    //保存-绩效
    async save(row, colName) {
      await this.$api.saveAPerformanceTrend({
        search_condition: this.searchCondition,
        data: {
          row: row,
          col_name: colName
        }
      })
    },
    /**
     * 保存-团队奖金
     * @param row
     * @param colName
     * @returns {Promise<void>}
     */
    async saveBonus(row, colName) {
      let arr = colName.split('_')
      let month = arr[1]
      await this.$api.saveBonus({
        search_condition: this.searchCondition,
        data: {type: 'trend', amount: row[colName], month: month}
      })
    },

    //发布已保存到数据
    async publishSaved() {
      this.commitLoading = true
      let info = await this.$api.publishPerformanceTrend({
        search_condition: this.searchCondition
      })
      this.commitLoading = false
      if (info === 'ok') {
        this.$notify.success('发布成功')
        this.editStatus = false
        await this.initList()
      }
    },
    rowClassName({row, rowIndex}) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },
    async rowContextmenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      // console.log(`红人：${ row.nickname },平台：${ row.platform_name },列：${ column.property }`)
      if (this.userPermissions.indexOf('performance_single_setting') > -1 && ['nickname'].indexOf(column.property) > -1) {
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top // container margin left

        const left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          this.left = maxLeft
        } else {
          this.left = left
        }
        const top = e.clientY - offsetTop
        this.$nextTick(() => {
          this.$refs['refTrendPerformancePlatformsSelect'].init(top, left, row)
        })

      }
    },
    savedPlatformEvent() {
      //修改完红人考核平台
      this.$confirm('红人考核平台已调整，是否刷新数据?', '提示', {
        confirmButtonText: '刷新',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.initList()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      })
    },
    headerContextmenu(column, e) {
      e.preventDefault()//阻止元素发生默认的行为
      // console.log(column, e)
      if (column.property.indexOf('quotient_') > -1) {
        let arr = column.property.split('_')
        // let month = arr[1]
        this.formQuotient.month = arr[1]
        this.formQuotient.year = this.searchCondition.year
        this.formQuotient.group_id = this.searchCondition.group_id
        // this.dialogQuotientVisible = true

        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        let left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          left = maxLeft
        }

        // const top = e.clientY
        const top = e.layerY + 100

        this.$nextTick(() => {
          this.$refs['refPerformanceQuotientEdit'].init(top, left, this.formQuotient)
        })
      }
    },
    updateQuotientCol(formQuotient) {
      //修改系数列
      let quotient = formQuotient.quotient
      let month = formQuotient.month
      this.dataList.forEach((row) => {
        let colName = `quotient_${month}`
        row[colName] = quotient
        row[`flag_value_${month}`] = quotient * row['average_value']
        this.save(row, colName)
      })
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.initList()
    },
    editRow() {
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度【${this.groupName}】涨粉数据-录入`
      this.drawerData.visible = true
      this.drawerData.loading = false
      this.$nextTick(() => {
        if (this.searchCondition.year && this.searchCondition.group_id && this.searchCondition.quarter)
          this.condition = {
            release_status: 'N',
            year: this.searchCondition.year,
            quarter: this.searchCondition.quarter,
            group_id: this.searchCondition.group_id,
            group_name: this.searchCondition.group_name,
            months: this.searchCondition.months
          }
        // this.$refs['refPerformanceTrendPanel'].init({ ...this.searchCondition })
      })
    },
    editTable() {
      if (this.mode === 'drawer') {
        this.editRow()
      }
    },
    showLog() {
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    },
    showSetting() {
      this.performanceSetting.visible = true
    },
    closeSetting() {
      this.performanceSetting.visible = false
      this.initList()
    },
    jumpToDetail(row) {
      const keyword = 'DingTalk'
      if (navigator.userAgent.indexOf(keyword) > -1) {
        // 获取订单配置
        this.$router.push({
          name: 'artist_trend_chart',
          params: {artist_id: row.artist_id},
          query: {group_name: row.group_name}
        })
      } else {
        const {href} = this.$router.resolve({
          name: 'artist_trend_chart',
          params: {artist_id: row.artist_id},
          query: {group_name: row.group_name}
        })
        window.open(href, '_blank')
      }
    },
    changeRelease() {
      this.initList()
    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({row, column, rowIndex, columnIndex}) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }

      //核算完成：
      let checkBool = false
      let months = this.checkMonthList
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        if (months.indexOf(month) > -1) {
          checkBool = true
        }
      }

      // console.log(`col:${ column.property },checkBool:${ checkBool }`)
      if (checkBool) {
        return 'performance-checked'
      } else
        return ''

    },

    async getCheckMonths() {
      let {months} = await this.$api.getPerformanceCheckedMonths({
        type: 'trend',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        months: this.quarterlyMonths
      })
      //当前已核算完成的月份
      this.checkMonthList = [...months]
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    }
  }
}
</script>

<style scoped>
.tips li {
  /*font-weight: lighter;*/
  font-size: 8px;
  /*font-style: italic;*/
  color: #909399;
  margin-bottom: 5px;
}

.table-view {
  max-height: 80vh;
  padding-bottom: 10px;
  padding-left: 5px;
}

.op-link {
  margin-left: 10px;
}

/**
核算样式
 */
.title-check {
  font-weight: 600;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
