<template>
  <div class='main-page-content'>
    <div class='flex-sb'>
      <el-form :model='searchCondition' :inline='true' size='mini'>
        <el-form-item>
          <el-date-picker
            v-model='searchCondition.year'
            type='year'
            format='yyyy年'
            value-format='yyyy'
            placeholder='选择年度' @change='loadMcnDeptList' style='width: 140px'>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <QuarterSelect size='mini' :quarter.sync='searchCondition.quarter' @change='loadMcnDeptList'></QuarterSelect>
        </el-form-item>
        <!--        <el-form-item v-if='searchCondition.release_status==="Y"'>-->
        <!--          <el-select v-model='searchCondition.group_id' @change='handleGroup' style='width: 220px' clearable>-->
        <!--            <el-option v-for='(group,index) in options.groups' :key='index' :value='group.group_id'-->
        <!--                       :label='`${group.dept_name}/${group.group_name}`'></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <DeptCascader ref='refSingleDeptSelect' :multiple='false' @handleSelect='selectDept'></DeptCascader>
        </el-form-item>
        <el-form-item v-if='showMore'>
          <artist-search @handleSelect='handleSelectKol'></artist-search>
        </el-form-item>
        <el-form-item label='' style='border-radius: 5px'>
          <el-radio-group size='mini' v-model='searchCondition.release_status' @change='handleChangeRelease'>
            <el-radio-button label='Y'>已发布</el-radio-button>
            <el-radio-button label='N'>编辑中</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click='handleTabClick'>查询</el-button>

          <el-button type='default' @click='reset'>重置</el-button>
          <el-button v-if='hasAuthority("pa_dept_kol_setting")' type='danger' icon='el-icon-setting'
                     @click='showKolSetting'>
            红人管理
          </el-button>
<!--          <el-button type='warning' @click='handleInit' :disabled='loadingSync' :loading='loadingSync'>初始化</el-button>-->
          <el-link type='primary' icon='el-icon-question' style='font-size: 1em;margin-left: 10px;'
                   @click='showTip=!showTip'></el-link>
        </el-form-item>

      </el-form>

    </div>
    <!--    Tab数据-->
    <div>
      <el-tabs v-model='activeName' class='performance-tab' tab-position='left' @tab-click='handleTabClick'>
        <el-tab-pane label='涨粉绩效目标' name='trend'>
          <PaDeptTrend ref='trend' :edit-mode='editMode' @handleSetting='showKolSetting' @handleBatch='showBatch' />
        </el-tab-pane>
        <el-tab-pane label='营收绩效目标' name='revenue'>
          <PaDeptRevenue ref='revenue' :edit-mode='editMode' @handleSetting='showKolSetting' @handleBatch='showBatch' />
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class='box-tip' v-if='showTip'>
      <el-steps :active='2' align-center>
        <el-step title='步骤1' description='初始化：项目组红人及其涨粉营收的季度目标'></el-step>
        <el-step title='步骤2' description='修改目标：红人的绩效季度目标，并发布'></el-step>
        <el-step title='步骤3' description='设置奖金：项目组每个月份的团队奖金'></el-step>
        <el-step title='步骤4' description='核算完成'></el-step>
      </el-steps>
    </div>

    <!--      批量设置涨粉目标-->
    <BatchSetPaTrend ref='batchSetPaTrend' type-range='dept'></BatchSetPaTrend>
    <!--      批量设置营收目标-->
    <BatchSetPaRevenue ref='batchSetPaRevenue' type-range='dept' />
    <!--    红人管理-->
    <PaKolSetting ref='refPaKolSetting'></PaKolSetting>
  </div>
</template>

<script>
import QuarterSelect from '@/components/time/QuarterSelect'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import PaDeptTrend from '@/pages/pa/dept/PaDeptTrend'
import ExportBtn from '@/components/export/ExportBtn'
import PaDeptRevenue from '@/pages/pa/dept/PaDeptRevenue'
import ArtistSearch from '@/components/artist/ArtistSearch'
import { mapGetters } from 'vuex'
import PaTrend from './PaTrend'
import PerformanceRevenue from './PerformanceRevenue'
import PaKolSetting from '@/pages/pa/dept/PaKolSetting'
import CompanyCascader from '@/components/dept/CompanyCascader'
import McnDeptSelect from '@/pages/pa/kol/McnDeptSelect'
import BatchSetPaTrend from '@/pages/pa/components/BatchSetPaTrend'
import BatchSetPaRevenue from '@/pages/pa/components/BatchSetPaRevenue'
import DeptCascader from './DeptCascader'

export default {
  name: 'PaDeptIndex',
  components: {
    DeptCascader,
    BatchSetPaRevenue,
    BatchSetPaTrend,
    McnDeptSelect,
    CompanyCascader,
    PaKolSetting,
    PerformanceRevenue,
    PaTrend,
    ArtistSearch,
    PaDeptRevenue,
    ExportBtn,
    PaDeptTrend,
    PageHeaderLayout,
    QuarterSelect
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      loadingSync: false,
      showMore: false,
      showTip: false,
      showOldVersion: false,
      searchCondition: {
        quarter: null,
        year: null,
        artist_id: null,
        dept_ids: [],//'911572023'
        group_id: null,
        group_name: null,//项目2—5组
        release_status: 'N'
      },
      options: { groups: [] },
      activeName: 'trend',
      activeMap: { 'trend': '涨粉', 'revenue': '营收' },
      quarters: [
        { label: '未选季度', value: 0 },
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 }
      ],
      //编辑模式
      editMode: false
    }
  },
  created() {
    this.defaultQuarter()
    // this.loadGroupOptions()
  },
  mounted() {
    // if (this.searchCondition.group_id && this.$refs['deptSelect'])
    //   this.$refs['deptSelect'].handleSetCurrent(this.searchCondition.group_id)

    this.handleTabClick()
  },
  methods: {
    reset() {
      this.searchCondition = { quarter: null, year: null, artist_id: null, dept_ids: null, release_status: 'N' }
      if (this.userPermissions.indexOf('pa_kol_batch_setting') > -1) {
        this.searchCondition.release_status = 'N'
      } else {
        this.searchCondition.release_status = 'Y'
      }

      this.defaultQuarter()
      this.handleTabClick()
    },
    handleTabClick() {
      this.$nextTick(() => {
        // this.$notify.success(JSON.stringify(this.searchCondition))
        if (this.searchCondition.year && this.searchCondition.quarter) {
          if (this.activeName === 'trend') {
            if (this.$refs['trend'])
              this.$refs['trend'].show(this.searchCondition)

            // if (this.searchCondition.dept_ids && this.searchCondition.dept_ids.length === 1) {
            //   if (this.$refs['refPerformanceTrend'])
            //     this.$refs['refPerformanceTrend'].show(this.searchCondition)
            // }
          } else if (this.activeName === 'revenue') {
            if (this.$refs['revenue'])
              this.$refs['revenue'].show(this.searchCondition)
            // if (this.searchCondition.dept_ids && this.searchCondition.dept_ids.length === 1) {
            //   if (this.$refs['refPerformanceRevenue'])
            //     this.$refs['refPerformanceRevenue'].show(this.searchCondition)
            // }
          } else {
            this.$notify.success(this.activeName)
          }
        }
      })

    },
    changeEditMode() {
      //
      this.editMode = !this.editMode
    },
    // selectDept(nodeIds) {
    //   // console.log(nodeIds)
    //   if (nodeIds && nodeIds.length > 0 && nodeIds[0]) {
    //     this.searchCondition.dept_ids = nodeIds
    //     this.searchCondition.group_id = nodeIds[0]
    //     this.searchCondition.group_name = this.$refs['deptSelect'].getNodeName(nodeIds[0])
    //     this.handleTabClick()
    //   } else {
    //     this.searchCondition.dept_ids = []
    //     this.searchCondition.group_id = null
    //     this.searchCondition.group_name = null
    //   }
    //
    // },

    handleChangeRelease() {
      if (this.searchCondition.release_status === 'Y') {
        // this.loadGroupOptions()
        this.editMode = false
      }

      this.handleTabClick()


    },
    handleSelectKol(row) {
      // this.$notify.info(JSON.stringify(row))
      if (row)
        this.searchCondition.artist_id = row.id || null
      else
        this.searchCondition.artist_id = null

      this.handleTabClick()
    },
    async exportExcel() {
      //
      try {
        let fileName = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度${this.activeMap[this.activeName]}绩效目标`

        if (this.activeName === 'trend') {
          let response = await this.$api.exportPaKolTrendQuarterData(this.searchCondition)
          this.$notify.warning(fileName)
          this.$refs['export'].exportSaveXlsx(response, fileName)
        } else if (this.activeName === 'revenue') {
          let response = await this.$api.exportPaKolRevenueQuarterData(this.searchCondition)
          this.$notify.warning(fileName)
          this.$refs['export'].exportSaveXlsx(response, fileName)
        } else {
          this.$notify.success(this.activeName)
        }

      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.$refs.export.hideLoading()
      }
    },
    defaultQuarter() {
      //当前季度
      this.searchCondition.year = (new Date()).getFullYear() + ''
      // 获取当前季度：
      this.searchCondition.quarter = this.$utils.getCurrentQuarter()
      this.loadMcnDeptList()
    },
    loadMcnDeptList() {
      // this.$nextTick(() => {
      //   if (this.$refs['deptSelect'])
      //     this.$refs['deptSelect'].getList()
      // })

      this.loadGroupOptions()

    },
    showKolSetting() {
      this.$refs['refPaKolSetting'].show(this.searchCondition)
    },
    showBatch() {
      let kolRange = []
      //批量设置
      if (this.activeName === 'trend') {
        kolRange = this.$refs['trend'].getCurrKolRange()
        if (kolRange.length === 0) {
          this.$notify.warning('请先选择有考核红人的项目组！！')
          return
        }
        this.$refs['batchSetPaTrend'].show(this.searchCondition, kolRange)
      } else if (this.activeName === 'revenue') {
        kolRange = this.$refs['revenue'].getCurrKolRange()
        if (kolRange.length === 0) {
          this.$notify.warning('请先选择有考核红人的项目组！！')
          return
        }
        this.$refs['batchSetPaRevenue'].show(this.searchCondition, kolRange)
      } else {
        this.$notify.success(this.activeName)
      }
    },
    async handleInit() {
      this.loadingSync = true
      this.$notify.success('初始化红人分组')
      await this.$api.syncPaGroupSetting(this.searchCondition)
      // this.$notify.success('初始化红人涨粉目标（同步自绩效目标一览）')
      // await this.$api.initPaDeptTrendDeptData(this.searchCondition)
      // this.$notify.success('初始化红人营收目标（同步自绩效目标一览）')
      // await this.$api.initPaDeptRevenueDeptData(this.searchCondition)

      await this.loadGroupOptions()
      this.loadingSync = false
    },
    async loadGroupOptions() {
      if (this.searchCondition.release_status === 'Y') {
        let { list } = await this.$api.loadPaGroupOptions(this.searchCondition)
        this.options.groups = list || []
        if (list && list.length > 0) {
          this.searchCondition.group_id = list[0].group_id
          this.searchCondition.group_name = list[0].group_name
          this.searchCondition.dept_ids = [this.searchCondition.group_id]
        }
      }

    },
    handleGroup(val) {
      if (val) {
        this.searchCondition.dept_ids = [val]
        this.searchCondition.group_id = val

        let obj = this.options.groups.find(value => value.group_id === val)
        this.searchCondition.group_name = obj ? obj.group_name : null
      } else {
        this.searchCondition.dept_ids = []
        this.searchCondition.group_id = null
        this.searchCondition.group_name = null
      }

      this.handleTabClick()
    },
    selectDept(nodeId) {
      // console.log('nodeIds', nodeId)
      if (nodeId) {
        this.searchCondition.dept_ids = [nodeId]
        this.searchCondition.group_id = nodeId
        this.searchCondition.group_name = this.$refs['refSingleDeptSelect'].getNodeName(nodeId)
      } else {
        this.searchCondition.dept_ids = null
        this.searchCondition.group_id = null
        this.searchCondition.group_name = null
      }

      this.handleTabClick()
    }
  }

}
</script>

<style scoped>
.box-tip {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 999;
  border: #e97ca1 1px solid;
  padding: 20px 10px;
  border-radius: 4px;
  width: 80%;
}
</style>
