<template>
  <div style='margin:10px;display: flex;flex-direction: row;justify-content: center;align-items: center;gap: 20px'>
    <div style='line-height: 20px;text-align: center;vertical-align: middle;'>
      <el-tooltip content='核算完成则锁定该月份的完成值和团队奖金' placement='bottom' effect='light'>
        <i class='el-icon-question theme-color' style='font-size: 1em'></i>
      </el-tooltip>
      <span class='title-check' :title='`若要修改核算完成数据，需要【权限-核算完成】`'>核算完成：</span>
      <el-checkbox-group v-model='checkMonthList' style='display: inline!important;'
                         v-if='quarterlyMonths&&quarterlyMonths.length>0'>
        <el-checkbox :label='month' :name='`month_${month}`' v-for='(month,index) in quarterlyMonths'
                     :key='index'
                     :disabled="userPermissions.indexOf('pa_dept_checked')===-1"
                     @change='changeCheckMonth(month)'>{{
            month
          }}月份
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div v-if='checkMonthList.length!==3'>
      <el-button plain type='primary' size='mini' @click='saveConfirm' :disabled='commitLoading'
                 v-if='showBtnPublish'>
        {{ commitLoading ? '提交中...' : `确认发布` }}
      </el-button>
    </div>
    <div class='tag-done' v-else>该季度已全部核算完成</div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckTrendPanel',
  props: {
    showBtnPublish: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter
      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    }
  },
  data() {
    return {
      commitLoading: false,
      searchCondition: {},
      checkMonthList: []
    }
  },
  methods: {
    show(searchCondition, checked_months) {
      this.searchCondition = searchCondition
      this.checkMonthList = checked_months
    },
    //确认操作
    saveConfirm() {
      let msg = `确认发布【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的涨粉绩效目标？发布后其他人将能看到最新的数据, 是否继续?`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.publishSaved()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    //发布已保存到数据
    async publishSaved() {
      this.commitLoading = true
      let info = await this.$api.publishPaDeptTrendDeptData(this.searchCondition)
      this.commitLoading = false
      if (info) {
        let msg = `${this.searchCondition.year}年${this.searchCondition.quarter}季度【${this.searchCondition.group_name}】涨粉绩效目标数据发布`
        this.$notify.success(msg)
        this.$emit('changed')
      }
    },
    //核算操作：
    async changeCheckMonth(month) {
      let params = {
        type: 'trend',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        month: month,
        check_status: this.checkMonthList.indexOf(month) > -1 ? 'Y' : 'N'
      }
      let info = await this.$api.setPerformanceCheckedMonth(params)
      if (info === 'ok') {
        this.$notify.success('操作成功')
        this.$emit('changed')
      }
    }
  }
}
</script>

<style scoped>
.title-check {
  font-size: 1.1em;
  font-weight: bold;
}
</style>
