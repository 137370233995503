<template>
  <div style='width: 100%'>
    <el-cascader
      v-model='value'
      :options='options'
      placeholder='请选择部门（公司内当前组织架构）'
      :props="{ expandTrigger: 'hover' ,value: valueType, label: 'name', multiple:multiple,emitPath:false,checkStrictly: true}"
      clearable
      collapse-tags style='min-width: 280px' @change='handleChange'></el-cascader>
  </div>
</template>

<script>
export default {
  name: 'DeptCascader',
  data() {
    return {
      data: [],
      options: [],
      deptProps: { value: 'dpt_id', label: 'name', children: 'children' },
      checkStrictly: false,
      filterText: '',
      selection: null,
      checkList: [],
      value: null
    }
  },
  props: {
    groupId: {
      type: String
    },
    type: {
      type: String,
      default: ''//revenue_data revenue_enter trend_data trend_enter
    },
    multiple: {
      type: Boolean,
      default() {
        return true
      }
    },
    size: {
      type: String,
      default() {
        return 'mini'
      }
    },
    showFirstGroup: {
      type: Boolean,
      default() {
        return false
      }
    },
    valueType: {
      type: String,
      default() {
        return 'dpt_id'
      }
    }

  },
  // watch: {
  //   filterText(val) {
  //     this.$refs.deptTree.filter(val)
  //   }
  // },
  methods: {
    //初始化
    init(val) {
      this.value = val ? val : null
      this.$emit('handleSelect', this.value)
    },
    clear() {
      this.value = null
      this.$emit('handleSelect', [])
    },
    //根据部门名称筛选【一般用于外部调用：指定部门】
    changeDeptByName(name) {
      let dept = this.data.find(value => value.name === name)
      if (dept) {
        this.value = dept.dpt_id
        this.handleChange([dept.parent_id, dept.dpt_id])
      }
    },
    handleChange(selectArr) {
      // let deptArr = []
      //
      // if (this.multiple) {
      //   selectArr.forEach((deptId) => {
      //     let dept = deptId[deptId.length - 1]
      //     deptArr.push(dept)
      //   })
      // } else {
      //   deptArr.push(selectArr[selectArr.length - 1])
      // }

      // console.log('handleSelect',deptArr)
      this.$emit('handleSelect', selectArr)
      return selectArr
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    async getList() {
      let { list } = await this.$api.getDepartmentAll(this.type)
      this.data = list

      this.calcOptions()
    },
    calcOptions() {
      this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
      // console.log(this.options)
      if (this.options.length > 0) {
        let dept = this.options[0]
        //计算第一个项目组
        this.calcLastDept(dept)
      }
    },
    calcLastDept(dept) {
      if (dept && dept['children']) {
        let firstChildDept = dept['children'][0]
        if (firstChildDept) {
          this.calcLastDept(firstChildDept)
        }
      } else {
        // console.log(dept)
        if (!this.multiple) {
          //单选
          this.value = dept.dpt_id || null
          // let deptSelect = this.data.find(value => value.dpt_id === this.value)
          // console.log(deptSelect)
          this.$emit('handleSelect', this.value)
        }
      }
    },
    getNodeName(val) {
      let obj = this.data.find(value => value.dpt_id === val)
      return obj ? obj.name : ''
    },
    //部门原始数据
    getNodeData() {
      return this.data
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    // if (this.data && this.data.length > 0 && this.groupId) {
    //   this.value = this.groupId
    //   let dept = this.data.find(value => value.dpt_id === this.value)
    //   if (dept)
    //     this.$emit('handleSelect', [dept])
    // }
  }
}
</script>

<style scoped>

</style>
