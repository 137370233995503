<template>
  <div>
    <el-row style='margin-bottom: 10px'>
      <el-col :span='24' style='text-align: right'>
        <el-button size='small' type='primary' @click='saveConfirm' :disabled='commitLoading'>
          {{ commitLoading ? '提交中...' : searchCondition.year + '年第' + searchCondition.quarter + '季度绩效 - 确认发布' }}
        </el-button>
      </el-col>
    </el-row>
    <div class='panel'>
      <div class='default-table'
           v-loading='loadingStatus'
           :element-loading-text='loadingText'
           element-loading-spinner='el-icon-loading'>
        <el-table
          :data='tblData'
          :span-method='objectSpanMethod'
          @row-contextmenu='rowContextmenu'
          @header-contextmenu='headerContextmenu'
          :row-class-name='rowClassName'
          :cell-class-name='cellClassName'
          style='width: 100%'
          border>
          <el-table-column
            prop='nickname'
            label='红人昵称'
            align='center'
            min-width='100'>
            <template slot-scope='{row}'>
              <artist-nickname :info='row' />
              <el-tooltip class='item' effect='dark' content='右键菜单，设置考核平台' placement='right'
                          v-if="userPermissions.indexOf('performance_single_setting') > -1">
                <el-link type='primary'><i class='el-icon-thumb'></i></el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop='platform_name'
            label='平台'
            align='center'
            min-width='100'>
            <template slot-scope='{row}'>
              <span>{{ row.platform_name }}</span>
              <el-tooltip class='item' effect='dark' content='右键菜单，设置考核该平台收入分类' placement='right'
                          v-if="userPermissions.indexOf('performance_single_setting') > -1">
                <el-link type='primary'><i class='el-icon-thumb'></i></el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop='income_type'
            label='收入分类'
            align='center'
            min-width='100'>
          </el-table-column>
          <el-table-column
            prop='average_value'
            label='上季月营收均值'
            min-width='100' header-align='center' align='center'>
          </el-table-column>
          <el-table-column v-for='(month,index) in quarterlyMonths' :key='index' :label='`${month}月`'
                           align='center'>
            <el-table-column
              :prop='`quotient_${month}`'
              label='系数'
              min-width='100' header-align='center' align='center'>
              <editable-cell
                v-if="$index!==tblData.length-2&&userPermissions.indexOf('performance_quotient_edit') !== -1"
                :show-input='row.editMode'
                slot-scope='{$index,row,column}'
                v-model='row[column.property]'
                @change='editVal(row,column.property,$index)' :is-input-number='false'>
                          <span slot='content'>
                            <span>{{ row[column.property] }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <editable-cell
                v-else-if="$index===tblData.length-1&&userPermissions.indexOf('performance_bonus_edit') !== -1"
                :show-input='row.editMode'
                slot-scope='{$index,row}'
                v-model='row[`amount_${ month }`]'
                @change='editVal(row,`amount_${ month }`,$index)'
                :is-input-number='true'>
                          <span slot='content'>
                            <span :class='calcBonusClass(row[`amount_${ month }`])'>{{
                                moneyFormat(row[`amount_${month}`])
                              }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <template slot-scope='{row,column}' v-else>
                <span>{{ row[column.property] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`flag_value_${month}`'
              label='目标'
              min-width='100' header-align='center' align='center'>
              <editable-cell
                v-if="isNaN(row[`quotient_${ month }`])&&$index<tblData.length-2&&userPermissions.indexOf('performance_quotient_edit') !== -1"
                :show-input='row.editMode'
                slot-scope='{$index,row,column}'
                v-model='row[column.property]'
                @change='editVal(row,column.property,$index)' :is-input-number='true'>
                          <span slot='content'>
                            <span>{{ row[`flag_value_${month}`] || 0 }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <editable-cell
                v-else-if="$index===tblData.length-1&&userPermissions.indexOf('performance_bonus_edit') !== -1"
                :show-input='row.editMode'
                slot-scope='{$index,row}'
                v-model='row[`amount_${ month }`]'
                @change='editVal(row,`amount_${ month }`,$index)'
                :is-input-number='true'>
                          <span slot='content'>
                            <span :class='calcBonusClass(row[`amount_${ month }`])'>{{
                                moneyFormat(row[`amount_${month}`])
                              }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <template slot-scope='{row}' v-else>
                <span>{{ valFormat(row[`flag_value_${month}`]) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`real_value_${month}`'
              label='完成'
              min-width='100' header-align='center' align='center'>
              <editable-cell
                v-if="$index===tblData.length-1&&userPermissions.indexOf('performance_bonus_edit') !== -1"
                :show-input='row.editMode'
                slot-scope='{$index,row}'
                v-model='row[`amount_${ month }`]'
                @change='editVal(row,`amount_${ month }`,$index)'
                :is-input-number='true'>
                          <span slot='content'>
                            <span :class='calcBonusClass(row[`amount_${ month }`])'>{{
                                moneyFormat(row[`amount_${month}`])
                              }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <editable-cell
                v-else-if="row.platform_code==='self_ec'&&userPermissions.indexOf('performance_quotient_edit') !== -1"
                :show-input='row.editMode'
                slot-scope='{$index,row}'
                v-model='row[`real_value_${ month }`]'
                @change='editVal(row,`real_value_${ month }`,$index)'
                :is-input-number='true'>
                          <span slot='content'>
                            <span>{{ valFormat(row[`real_value_${month}`]) }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <template slot-scope='{row}' v-else>
                <span>{{ valFormat(row[`real_value_${month}`]) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <el-divider />
      <el-row :gutter='15'>
        <el-col :span='8' v-for='(month,idx) in quarterlyMonths' :key='idx'>
          <el-button size='large' style='width: 100%'
                     :type="`${checkMonthList.indexOf(month) > -1?'success':'primary'}`"
                     @click='saveConfirmByMonth(month)'
                     :disabled='commitLoading||checkMonthList.indexOf(month) > -1'>
            {{ commitLoading ? '提交中...' : searchCondition.year + '年' + month + '月 营收绩效目标与团队奖金' + ' - ' }}
            {{ checkMonthList.indexOf(month) > -1 ? '【已核算】' : '确认发布' }}
          </el-button>
        </el-col>
      </el-row>
      <el-divider />
      <el-row>
        <el-col :span='24'>
          <Tips :data='tipsList' />
        </el-col>
      </el-row>

    </div>

    <!--    系数修改-->
    <PerformanceQuotientEdit ref='refPerformanceQuotientEdit' @savedEvent='updateQuotientCol' />

    <PerformancePlatformsSelect :performance-type='`revenue`' ref='refPerformancePlatformsSelect'
                                @savedEvent='savedPlatformEvent' />
    <!--          平台收入分类设置-->
    <PerformanceSetting :performance-type='`revenue`' ref='refPerformanceSetting'
                        @savedEvent='savedPlatformEvent' />

    <!--    右键菜单-修改红人考核月份-->
    <Contextmenu :menu-data='menuData' @change='changeCheck' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { numberFormat } from '@/utils/utils'
import EditableCell from '@/components/EditableCell'
import PerformanceQuotientEdit from '@/pages/performance/components/PerformanceQuotientEdit'
import PerformancePlatformsSelect from '@/pages/performance/components/PerformancePlatformsSelect'
import PerformanceSetting from '@/pages/performance/components/PerformanceSetting'
import Contextmenu from '@/pages/performance/components/Contextmenu'
import Tips from '@/components/Tips'

export default {
  name: 'PerformanceRevenuePanel',
  components: {
    EditableCell, PerformanceQuotientEdit, PerformancePlatformsSelect, PerformanceSetting, Contextmenu, Tips
  },
  data() {
    return {
      tipsList: [
        '鼠标左键“红人昵称”：调整到红人详情页面',
        '鼠标右键“红人昵称”：设置考核平台',
        '鼠标右键“平台名称”：设置考核平台的收入分类',
        '鼠标右键表头“系数”：批量修改指定月份的考核系数',
        '鼠标右键单元格“系数/目标/完成”：设置考核月份'
      ],
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      //end:鼠标右键菜单
      commitLoading: false,
      showEditIcon: true,
      currentRow: {},
      currentColumn: {},
      platforms: [],//红人所有平台
      showPlatforms: [],//要显示的平台
      loadingStatus: false,
      showIndex: false,
      mergeFields: ['nickname', 'platform_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      fixFields: ['group_name', 'platform_name', 'income_type', 'average_value'],
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      searchCondition: {
        months: []
      },
      dataList: [],
      bonusRow: {},
      bonus_items: [],//团队奖金
      // tblData: [],//表格数据
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      },
      checkMonthList: []//核算完成的月份
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    loadingText() {
      return `【${this.searchCondition.year}】年第【${this.searchCondition.quarter}】季度【${this.searchCondition.group_name}】组的营收绩效目标`
    },
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter
      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    },
    formQuotientTitle() {
      return `批量修改【${this.formQuotient.year}年${this.formQuotient.month}月】【${this.searchCondition.group_name}】营收绩效系数`
    },
    tblData() {
      if (this.dataList.length > 0) {
        let calcRow = this.calcCol()

        //合计数据-初始化
        let appendRows = [{ nickname: '本组总营收', platform_name: '本组总营收', income_type: '本组总营收', ...calcRow }]
        appendRows.push({ ...this.bonusRow })

        return [...this.dataList, ...appendRows]
      }

      return []
    }
  },
  created() {
  },
  methods: {
    async init(currentRow, searchCondition) {
      this.currentRow = currentRow
      this.searchCondition = searchCondition
      this.searchCondition.nickname = currentRow.nickname
      this.searchCondition.artist_id = currentRow.artist_id
      await this.initList()
    },
    async initList() {
      this.getCalcFields()
      //当前已核算完成的月份
      // await this.getCheckMonths()

      this.loadingStatus = true
      this.dataList = []
      let { list, bonus_items, checked_months } = await this.$api.getPerformanceRevenueArtistData(this.searchCondition)
      this.checkMonthList = checked_months || []
      this.bonus_items = bonus_items
      if (this.bonus_items.length > 0) {
        let row = {}
        this.bonus_items.forEach((item) => {
          row[`amount_${item.month}`] = item.amount
        })
        this.bonusRow = { nickname: '团队奖金', platform_name: '团队奖金', income_type: '团队奖金', average_value: '团队奖金', ...row }
      }

      this.$nextTick(() => {
        this.dataList = list
        this.loadingStatus = false
        this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      })
    },
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1
              }
            })
          }
        })
      }
      return calcRow
    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化

          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.dataList.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][field] === this.dataList[index - 1][field]) {
              if (field === 'platform_name') {
                //平台列，特殊处理
                if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                  this.spanObj[field][this.positionObj[field]] += 1
                  this.spanObj[field].push(0)
                } else {
                  this.spanObj[field].push(1)
                  this.positionObj[field] = index
                }
              } else {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              }

            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //末尾查询一行合计：
      if (rowIndex === this.tblData.length - 1) {
        // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
        if (columnIndex < 4) {
          if ((columnIndex + 1) % 4 === 0) {
            return [1, 4]
          } else {
            return [0, 0]
          }
        } else if ((columnIndex + 1) % 3 === 0) {
          return [1, 3]
        } else {
          return [0, 0]
        }
      } else if (rowIndex === this.tblData.length - 2) {
        // 本组总涨粉：
        if (columnIndex < 2) {
          return [0, 0]
        } else if (columnIndex === 2) {
          return [1, 3]
        } else {
          return [1, 1]
        }
      } else {
        const fields = this.mergeFields
        if (column.property && fields.indexOf(column.property) > -1) {
          const _row = this.spanObj[column.property][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }
    },

    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    // eslint-disable-next-line
    editVal(row, colName, rowIndex) {
      // console.log(`当前操作第${rowIndex},列${colName},isNaN:${isNaN(row[colName])}`)
      if (!isNaN(row[colName])) {
        row[colName] = row[colName] * 1
        //修改系数值-同步计算目标值
        if (colName.indexOf('quotient_') > -1) {
          let arr = colName.split('_')
          let syncField = `flag_value_${arr[1]}`
          row[syncField] = row[colName] * row['average_value']
        }

        //团队奖金数据同步，重新计算表格数据的时候要使用
        if (colName.indexOf('amount_') > -1) {
          // this.bonusRow[colName] = row[colName];
          this.saveBonus(row, colName)
        } else {
          //实时保存
          this.save(row, colName)
        }
      }
    },
    //保存
    async save(row, colName) {
      await this.$api.saveAPerformanceRevenue({
        search_condition: this.searchCondition,
        data: {
          row: row,
          col_name: colName
        }
      })
    },
    /**
     * 保存-团队奖金
     * @param row
     * @param colName
     * @returns {Promise<void>}
     */
    async saveBonus(row, colName) {
      let arr = colName.split('_')
      let month = arr[1]
      await this.$api.saveBonus({
        search_condition: this.searchCondition,
        data: { type: 'revenue', amount: row[colName], month: month }
      })
    },
    headerContextmenu(column, e) {
      this.formQuotient = {}
      e.preventDefault()//阻止元素发生默认的行为
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value') > -1) {
        let arr = column.property.split('_')
        // let month = arr[1]performance_single_setting
        this.formQuotient.month = arr[1]
        this.formQuotient.year = this.searchCondition.year
        this.formQuotient.group_id = this.searchCondition.group_id
        // this.dialogQuotientVisible = true
        if (column.property.indexOf('quotient_') > -1) {
          this.formQuotient.month = arr[1]
          this.formQuotient.type = 'quotient'
        } else {
          this.formQuotient.month = arr[2]
          this.formQuotient.type = 'flagValue'
        }
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        let left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          left = maxLeft
        }

        // const top = e.clientY
        const top = e.layerY + 100

        this.$nextTick(() => {
          this.$refs['refPerformanceQuotientEdit'].init(top, left, this.formQuotient)
        })
      }
    },

    updateQuotientCol(formQuotient) {
      let month = formQuotient.month

      //修改系数列
      if (formQuotient.type === 'quotient') {
        let quotient = formQuotient.data
        this.dataList.forEach((row) => {
          let colName = `quotient_${month}`
          row[colName] = quotient
          // 若系数是数值，则进行乘法计算得到目标值；否则目标值为0
          if (!isNaN(quotient))
            row[`flag_value_${month}`] = quotient * row['average_value']
          this.save(row, colName)
        })
      } else {
        let flag_value = formQuotient.data
        this.dataList.forEach((row) => {
          let colName = `flag_value_${month}`
          row[colName] = flag_value
          row[`quotient_${month}`] = '/'
          this.save(row, colName)
        })
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 2) {
          return
        }
        if (index === 2) {
          sums[index] = '合计:'
          return
        }
        // console.log(`${index}）${column.property}`)
        if (column.property.indexOf('average_value') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // // 格式化数据
          switch (column.property) {
            default:
              sums[index] = this.valFormat(sums[index])
              break
          }
        }
      })

      return sums
    },
    //表格事件：行右键
    async rowContextmenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      // console.log(`红人：${row.nickname},平台：${row.platform_name},列：${column.property}`)
      if (this.userPermissions.indexOf('performance_single_setting') > -1) {
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top // container margin left

        const left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          this.left = maxLeft
        } else {
          this.left = left
        }
        const top = e.clientY - offsetTop

        this.closeAllMenu()

        if (['nickname'].indexOf(column.property) > -1) {
          this.$nextTick(() => {
            this.$refs['refPerformancePlatformsSelect'].init(top, left, row)
          })
        } else if (['platform_name'].indexOf(column.property) > -1) {
          this.$nextTick(() => {
            this.$refs['refPerformanceSetting'].init(top, left, row)
          })
        }
      }

      //完成值列-右键事件
      this.openMenu(row, column, e)
    },
    closeAllMenu() {
      if (this.$refs['refPerformancePlatformsSelect'])
        this.$refs['refPerformancePlatformsSelect'].closeMenu()

      if (this.$refs['refPerformanceSetting'])
        this.$refs['refPerformanceSetting'].closeMenu()
    },
    savedPlatformEvent() {
      this.initList()
    },
    saveConfirmByMonth(month) {
      let msg = `确认发布【${this.searchCondition.year}】年${month}月(第【${this.searchCondition.quarter}】季度)【${this.searchCondition.group_name}】组的营收绩效目标？发布后其他人将能看到最新的数据, 是否继续?`

      // let msg = `确认保存【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的涨粉绩效目标信息, 是否继续?`;
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.publishSaved(month)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消【发布】'
        })
      })

    },
    //确认操作
    saveConfirm() {
      let msg = `确认发布【${this.searchCondition.year}】年第【${this.searchCondition.quarter}】季度【${this.searchCondition.group_name}】组的营收绩效目标？发布后其他人将能看到最新的数据, 是否继续?`

      // let msg = `确认保存【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的涨粉绩效目标信息, 是否继续?`;
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.publishSaved()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消【发布】'
        })
      })

    },

    //发布已保存到数据
    async publishSaved(month) {
      this.commitLoading = true
      let searchCondition = { ...this.searchCondition }
      if (month) {
        searchCondition.months = [month]
      }

      let info = await this.$api.publishPerformanceRevenue({
        search_condition: searchCondition
      })
      this.commitLoading = false
      if (info === 'ok') {
        let msg = month ? `${this.searchCondition.year}年${month}月绩效数据已发布` : `${this.searchCondition.year}年${this.searchCondition.quarter}季度绩效数据已发布`
        this.$notify.success(msg)
        this.editStatus = false
        await this.initList()
      }
    },

    jumpToDetail(row) {
      const keyword = 'DingTalk'
      if (navigator.userAgent.indexOf(keyword) > -1) {
        // 获取订单配置
        this.$router.push({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
      } else {
        const { href } = this.$router.resolve({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
        window.open(href, '_blank')
      }
    },
    async changeCheckMonth(month) {

      let params = {
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        month: month,
        check_status: this.checkMonthList.indexOf(month) > -1 ? 'Y' : 'N'
      }
      let info = await this.$api.setPerformanceCheckedMonth(params)
      if (info === 'ok') {
        this.$notify.success('操作成功')
      }
    },
    async getCheckMonths() {
      let { months } = await this.$api.getPerformanceCheckedMonths({
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        months: this.quarterlyMonths
      })
      //当前已核算完成的月份
      this.checkMonthList = [...months]
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({ row, column, rowIndex, columnIndex }) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }

      //核算完成：
      let checkBool = false
      let months = this.checkMonthList
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        if (months.indexOf(month) > -1) {
          checkBool = true
        }
      }

      // console.log(`col:${ column.property },checkBool:${ checkBool }`)
      if (checkBool) {
        return 'performance-checked'
      } else
        return ''

    },
    //右键菜单：设置是否月考核
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    },
    openMenu(row, column, e) {
      let index = row.index
      if (index <= this.dataList.length) {
        if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
          this.currentRow = row
          this.currentColumn = column
          e.preventDefault()//阻止元素发生默认的行为
          const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
          // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
          this.menuData = {
            visible: true,
            left: e.clientX - offsetLeft,
            top: e.clientY
          }
          document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
        }
      }
    },
    changeCheck(val) {
      let arr = this.currentColumn.property.split('_')
      let month = parseInt(arr[arr.length - 1])//数据类型：整型
      let colName = `need_check_${month}`
      this.currentRow[colName] = val
      this.save(this.currentRow, colName)
      let index = this.currentRow.index
      this.dataList[index - 1][colName] = val
      // console.log(this.dataList)
    }
  }
}
</script>

<style scoped>
.panel {
  margin-bottom: 50px;
}
</style>
